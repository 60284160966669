import * as React from "react"
import * as classes from "./header.module.scss"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Header = ({
  siteTitle,
  navOpen,
  setNavOpen,
  sidebarButtonId,
  sidebarId,
  isSmallScreen,
}) => (
  <header className={classes.header}>
    <a href="#main" className="srText">
      Skip to Content
    </a>
    <Link to="/">{siteTitle}</Link>

    {isSmallScreen && (
      <button
        onClick={() => {
          setNavOpen(!navOpen)
        }}
        aria-haspopup="true"
        aria-controls={sidebarId}
        aria-expanded={navOpen}
        id={sidebarButtonId}
      >
        {navOpen ? "Close Menu" : "Open Menu"}
      </button>
    )}
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
