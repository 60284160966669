const dets = {
  stopTimes: {
    name: "GStopTimes",
    pageName: "gstoptimes",
    schemaId: "g-stop-time-schema",
    schema: [
      { key: "trip_id", type: "string", description: "The trip id", og: true },
      {
        key: "arrival_time",
        type: "string",
        description: "The arrival time",
        og: true,
      },
      {
        key: "departure_time",
        type: "string",
        description: "The departure time",
        og: true,
      },
      { key: "stop_id", type: "string", description: "The stop id", og: true },
      {
        key: "stop_sequence",
        type: "number",
        description: "The stop sequence in this trip",
        og: true,
      },
      {
        key: "stop_headsign",
        type: "string",
        description: "The headsign",
        og: true,
      },
      { key: "pickup_type", type: "string (enum)", description: "", og: true },
      {
        key: "drop_off_type",
        type: "string (enum)",
        description: "",
        og: true,
      },
      {
        key: "shape_dist_traveled",
        type: "number",
        description: "Distance travelled in this trip (meters)",
        og: true,
      },
      { key: "stop_name", type: "string", description: "The stop name" },
      { key: "stop_lon", type: "number", description: "The stop longitude" },
      { key: "stop_lat", type: "number", description: "The stop latitude" },
      { key: "route_id", type: "string", description: "The route id" },
      { key: "service_id", type: "string", description: "The service id" },
      {
        key: "trip_headsign",
        type: "string",
        description: "The trip headsign",
      },
      {
        key: "direction_id",
        type: "string (enum)",
        description: "The direction ID of this trip",
      },
      {
        key: "route_short_name",
        type: "string",
        description: "The route short name eg 402",
      },
      {
        key: "first_stop_id",
        type: "string",
        description: "ID of first stop on this trip",
      },
      {
        key: "first_stop_name",
        type: "string",
        description: "Name of first stop on this trip",
      },
      {
        key: "last_stop_id",
        type: "string",
        description: "ID of last stop on this trip",
      },
      {
        key: "last_stop_name",
        type: "string",
        description: "Name of last stop on this trip",
      },
    ],
    routes: [
      {
        name: "bystopid",
        desc: "Gets a GStopTime for each scheduled departarture on the date specified. Defaults to current date and includes all bus routes.",
        id: "gstoptimes-by-stopid",
        uri: "/api/gstoptimes/bystopid/:stopid/:date",
        title: "GET GStopTimes by stopid and date",
        params: [
          { name: "stopid", required: "true", type: "string" },
          {
            name: "date",
            type: "string",
            required: "false",
            description: "yyyymmdd",
          },
        ],
      },
      {
        name: "bystopidrouteid",
        id: "gstoptimes-by-stopid-routeid",
        uri: "/api/gstoptimes/bystopidrouteid/:stopid/:route/:direction/:date",
        title: "GET GStopTimes by stopid, routeid",
        params: [
          { name: "stopid", type: "string", required: "true" },
          { name: "route", type: "string", required: "true" },
          {
            name: "direction",
            type: "string",
            required: "~false",
            description: "Required if date param is used",
          },
          {
            name: "date",
            type: "string",
            required: "false",
            description: "yyyymmdd Default is current date",
          },
        ],
      },
      {
        name: "bytripid",
        desc: "Returns a stop time for each bus stop on the trip",
        id: "gstoptimes-by-tripid",
        uri: "/api/gstoptimes/bytripid/:tripid",
        title: "GET GStopTimes by tripid",
        params: [{ name: "tripid", type: "string", required: "true" }],
      },
    ],
  },
  realTime: {
    name: "RealTime",
    pageName: "realtime",
    schema: [
      // {key: 'trip_id', type: 'string', description: 'The trip id', og: 'true'},
      // {key: 'arrival_time', type: 'string', description: 'The arrival time', og: 'true'},
      // {key: 'departure_time', type: 'string', description: 'The departure time', og: 'true'},
      // {key: 'stop_id', type: 'string', description: 'The stop id', og: 'true'},
      // {key: 'stop_name', type: 'string', description: 'The stop name', og: 'true'},
      // {key: 'stop_lon', type: 'number', description: 'The stop longitude', og: 'true'},
      // {key: 'stop_lat', type: 'number', description: 'The stop latitude', og: 'true'},
      // {key: 'stop_sequence', type: 'number', description: 'The stop sequence in this trip', og: 'true'},
      // {key: 'shape_dist_traveled', type: 'number', description: 'Distance travelled in this trip (meters)', og: 'true'},
      // {key: 'route_id', type: 'string', description: 'The route id', og: 'true'},
      // {key: 'service_id', type: 'string', description: 'The service id', og: 'true'},
      // {key: 'trip_headsign', type: 'string', description: 'The trip headsign', og: 'true'},
      // {key: 'direction_id', type: 'string (enum)', description: 'The direction ID of this trip', og: 'true'},
      // {key: 'first_stop_id', type: 'string', description: 'ID of first stop on this trip', og: 'true'},
      // {key: 'first_stop_name', type: 'string', description: 'Name of first stop on this trip', og: 'true'},
      // {key: 'last_stop_id', type: 'string', description: 'ID of last stop on this trip', og: 'true'},
      // {key: 'last_stop_name', type: 'string', description: 'Name of last stop on this trip', og: 'true'},
      {
        key: "rt",
        type: "object (or null)",
        description: `The relevant entity from the realtime gtfs feed. (https://developers.google.com/transit/gtfs-realtime/)`,
      },
      {
        key: "rt_timestamp",
        type: "string",
        description: "Timestamp from feed",
      },
      {
        key: "departure_delay",
        type: "number",
        description:
          "Delay in seconds for this stop time. Negative number is early, positive is late",
      },
      {
        key: "arrival_delay",
        type: "number",
        description:
          "Delay in seconds for this stop time. Negative number is early, positive is late",
      },
      {
        key: "rt_info",
        type: "string",
        description:
          "Details about how galway-bus-api derived realtime info from the feed (https://gtfs.org/realtime/trip-updates/#stop-time-updates)",
      },
    ],
    schemaId: "realtime-schema",
    rtSchema: [{ key: "id", type: "string", description: "" }],
    routes: [
      {
        name: "realtime-by-tripid",
        id: "realtime-by-tripid",
        uri: "/api/realtime/bytripid/:tripid",
        title: "GET feed by tripid",
        params: [
          { name: "tripid", description: "The trip_id", required: "true" },
        ],
      },
      {
        name: "realtime-galway",
        id: "realtime-galway",
        uri: "/gstoptimes/bystopidrouteid/:stopid/:route/:direction/:date",
        title: "GET feed for Galway City",
      },
      {
        name: "realtime-by-stopid",
        //desc: "Returns a stop time for each bus stop on the trip",
        id: "realtime-by-stopid",
        uri: "/realtime/bystopid/:stopid/:route/:direction",
        title: "GET RealTime by stopid",
        params: [
          { name: "stopid", description: "The stop_id", required: "true" },
          {
            name: "route_short_name",
            description: "The route_short_name",
            required: "false",
          },
          {
            name: "direction_id",
            description: "The direction_id (1 or 0)",
            required: "false",
          },
        ],
      },
      {
        name: "realtimestop-by-stopid",
        hide: true,
        desc: "Returns a stop time for each bus stop on the trip",
        id: "realtimestop-by-stopid",
        uri: "/realtimestop/:stopid",
        title: "GET GStopTimes if realtime info exists",
        params: [
          { name: "stopid", description: "The stop_id", required: "true" },
        ],
      },
      {
        name: "realtime-by-tripid-match",
        //desc: "Returns a stop time for each bus stop on the trip",
        id: "realtime-by-tripid-match",
        uri: "/realtime/bytripidmatch/:tripid",
        title: "GET RealTime by tripid",
        params: [
          { name: "tripid", description: "The trip_id", required: "true" },
        ],
      },
    ],
  },
  gRoute: {
    name: "GRoute",
    pageName: 'g-route',
    schema: [
      {
        key: "route_short_name",
        type: "string",
        description: "The route name eg. 405",
        og: true,
      },
      {
        key: "route_long_name",
        type: "string",
        description:
          "The route long name (not always accurrate, use route variations first/last stop).",
        og: true,
      },
      {
        key: "direction_id",
        type: "string",
        description: "The route direction",
        og: false,
      },
      {
        key: "g_trip_headsign",
        type: "string",
        description: "The trip headsign",
        og: false,
      },
      {
        key: "g_route_variations",
        type: "array",
        description:
          "Contains objects describing route variations - eg. Early morning/Late night trips covering only half of the bus route",
        og: false,
      },
      {
        key: "g_stops",
        type: "array",
        description: "Array of Stops on the route",
        og: false,
      },
      {
        key: "g_route_ids",
        type: "array",
        description: "The route ids (see note)",
        og: false,
      },
    ],
    schemaId: "g-route-schema",
    routes: [
      {
        name: "route_id",
        desc: "",
        id: "g-route-get-all-bus-routes",
        uri: "/api/g-route/",
        title: "GET all GRoutes",
        params: [],
      },
      {
        name: "byrouteiddirectionid",
        id: "g-route-by-routeid-directionid",
        uri: "/api/groute/:route_id/:direction",
        title: "GET GRoutes by routeid, directionid",
        params: [
          {
            name: "route",
            type: "string",
            required: "false",
            description: "Accepts route_id or route_short_name",
          },
          {
            name: "direction",
            type: "enum",
            required: "false",
            description: `Either '1' or '0'.  If not provided the response will include both directions`,
          },
        ],
      },
    ],
  },
  gStop: {
    name: "GStop",
    pageName: "g-stop",
    schema: [
      { key: "stop_id", type: "string", description: "The stop id", og: true },
      {
        key: "stop_name",
        type: "string",
        description: "The stop name",
        og: true,
      },
      {
        key: "stop_lat",
        type: "number",
        description: "The stop latitude",
        og: true,
      },
      {
        key: "stop_lon",
        type: "number",
        description: "The stop longitude",
        og: true,
      },
      {
        key: "g_routes_data",
        type: "array",
        description:
          "Contains objects describing routes that this stop is on - route_id, route_short_name, direction_id, typical_stop_sequence_on_route",
        og: false,
      },
      {
        key: "g_routes",
        type: "array (of strings)",
        description: "route_short_name of routes that this stop is on",
        og: false,
      },
    ],
    schemaId: "g-stop-schema",
    routes: [
      {
        name: "gstop",
        id: "g-stops-all",
        uri: "/gstopsp",
        title: "GET all GStops",
        params: [],
      },
      {
        name: "gstop",
        id: "g-stops-by-stopid",
        uri: "/api/gstop/bystopid/:stopid",
        title: "GET GStop by stopid",
        params: [
          {
            name: "stopid",
            type: "string",
            required: "false",
            description: "The stop id",
          },
        ],
      },
      {
        name: "gstops",
        id: "g-stops-by-routeid-directionid",
        uri: "/api/gstop/byrouteid/:route/:direction",
        title: "GET GStop by route, direction",
        params: [
          {
            name: "route",
            type: "string",
            required: "false",
            description: "Accepts long or short",
          },
          {
            name: "direction",
            type: "enum",
            required: "false",
            description: `Either '1' or '0'`,
          },
        ],
      },
    ],
  },
  routeTimetable: {
    name: "Route Timetable",
    pageName: "routetimetable",
    schema: [
      { key: "trip_id", type: "string", description: "The trip id" },
      {
        key: "offset_dets",
        type: "object",
        description: "Offset details or empty object",
      },
      { key: "stop_times", type: "array", description: "Array of GStopTimes" },
    ],
    schemaId: "route-timetable-schema",
    routes: [
      {
        name: "routetimetable",
        id: "route-timetables-all",
        uri: "/api/routetimetables/:route/:direction",
        title: "Get Route Timetable",
        params: [
          {
            name: "route",
            type: "string",
            required: "true",
            description: "Accepts long or short",
          },
          {
            name: "direction",
            type: "enum",
            required: "true",
            description: `Either '1' or '0'`,
          },
        ],
      },
    ],
  },
  shape: {
    name: "Shape",
    schemaId: "shape-schema",
    pageName: "shape",
    routes: [
      {
        name: "shape",
        id: "shape-by-tripid",
        uri: "/api/shapebytripid/:tripid",
        title: "GET shape by tripid",
        params: [
          {
            name: "tripid",
            type: "string",
            required: "true",
            description: "A trip id",
          },
        ],
      },
    ],
  },
}
export default dets
