import React, { useState, useEffect, useRef, useCallback } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./layout.css"
import * as classes from "./layout.module.scss"

import Split from "react-split"
import Header from "./header/Header"
import Sidebar from "./sidebar/Sidebar"

const Layout = ({ location, children }) => {
  const [sizes, setSizes] = useState([20, 80])
  const [gutterIsFocused, setGutterIsFocused] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(null)
  const [navOpen, setNavOpen] = useState(false)
  const sidebarId = "sidebar-menu"
  const sidebarButtonId = "sidebar-button"
  const afterGutterRef = useRef(null)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const gutterFocusedRef = useRef()
  useEffect(() => {
    gutterFocusedRef.current = gutterIsFocused
  })
  const prevGf = gutterFocusedRef.current

  const handleGutterKeyboard = useCallback(
    function (e) {
      if (e.key !== "ArrowRight" && e.key !== "ArrowLeft") return

      e.preventDefault()

      if (e.key === "ArrowLeft") {
        const newSizes = [
          sizes[0] >= 2 ? (sizes[0] -= 2) : sizes[0],
          sizes[1] <= 98 ? (sizes[1] += 2) : sizes[1],
        ]
        setSizes(newSizes)
      } else if (e.key === "ArrowRight") {
        const newSizes = [
          sizes[0] <= 98 ? (sizes[0] += 2) : sizes[0],
          sizes[1] >= 2 ? (sizes[1] -= 2) : sizes[1],
        ]

        setSizes(newSizes)
      }
      return
    },
    [sizes]
  )

  const handleResize = useCallback(
    function () {
      const update = window.innerWidth < 900
      if (isSmallScreen !== update) {
        setIsSmallScreen(update)
      }
    },
    [isSmallScreen]
  )

  // onload
  useEffect(() => {
    let mql = window.matchMedia("(max-width: 900px)")
    const isSmall = mql.matches
   
    if (!isSmall) {
      setIsSmallScreen(false)
      return
    }
    setIsSmallScreen(true)
  }, [])

  // close nav sidebar if the url changes
  useEffect(() => {
    setNavOpen(false)
  }, [location])

  // need to add focus event listeners to the gutter when it's created
  useEffect(() => {
    // Only run if gutter focus state has changed.
    if (prevGf === gutterIsFocused) return

    // Use left and right arrow keys to resize the split panels.

    if (gutterIsFocused) {
      document.activeElement.addEventListener(
        "keydown",
        handleGutterKeyboard,
        false
      )
    }

    if (!gutterIsFocused) {
      document.activeElement.removeEventListener(
        "keydown",
        handleGutterKeyboard,
        false
      )
    }
  }, [gutterIsFocused, handleGutterKeyboard, prevGf])
  // CHeck if page is resized

  useEffect(() => {
    window.addEventListener("resize", handleResize, false)
    return () => window.removeEventListener("resize", handleResize, false)
  }, [handleResize])

  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata?.title || `Title`}
        navOpen={navOpen}
        setNavOpen={setNavOpen}
        sidebarId={sidebarId}
        sidebarButtonId={sidebarButtonId}
        isSmallScreen={isSmallScreen}
      />

      <div className={classes.layout}>
        {isSmallScreen === false ? (
          sizes && (
            <Split
              sizes={sizes}
              className="split"
              minSize={0}
              gutterSize={16}
              // This is the split.js function that creates the gutter. See https://github.com/nathancahill/split/tree/master/packages/splitjs#gutter.

              // I need to use a button instead of a div for create element so it is tabbable to. Note I'm adding focus and blur events to the gutter button. When the gutter is focused useEffect above adds a 'keydown' keyboard listener so that the gutter can be moved using the 'ArrowLeft' and 'ArrowRight' keys. The listener is removed on gutter.blur.
              gutter={(_, direction) => {
                const gutter = document.createElement("button")
                gutter.className = `gutter gutter-${direction}`
                gutter.innerHTML = `<span class="srText">Resize panels</span>`

                gutter.addEventListener("focus", function (e) {
                  e.preventDefault()
                  setGutterIsFocused(true)
                })
                gutter.addEventListener("blur", function () {
                  setGutterIsFocused(false)
                })
                return gutter
              }}
              gutterAlign="center"
              direction="horizontal"
              cursor="col-resize"
            >
              <Sidebar
                isSmallScreen={isSmallScreen}
                setNavOpen={setNavOpen}
                hash={location ? location.hash : null}
              />
              <main ref={afterGutterRef} id="main">
                {children}
              </main>
            </Split>
          )
        ) : isSmallScreen === true ? (
          <div className={classes.notSplit}>
            <Sidebar
              isSmallScreen={isSmallScreen}
              navOpen={navOpen}
              setNavOpen={setNavOpen}
              sidebarButtonId={sidebarButtonId}
              sidebarId={sidebarId}
            />
            <main id="main">{children}</main>
          </div>
        ) : null}
        <footer>© <a href="https://marie.ie">Marie</a> {new Date().getFullYear()} </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
