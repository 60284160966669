import React from "react"
import { Link } from "gatsby"
import * as classes from "./sidebar.module.scss"
import dets from "../../../dets.js"
const Sidebar = ({
  isSmallScreen,
  navOpen,
  sidebarButtonId,
  sidebarId,
  setNavOpen,
  hash,
}) => {

  return (
    <div
      className={
        !isSmallScreen
          ? classes.sidebar
          : isSmallScreen && !navOpen
          ? `${classes.sidebarTransition}`
          : `${classes.sidebarTransition} ${classes.navOpen}`
      }
      aria-labelledby={sidebarButtonId}
      id={sidebarId}
    >
      <nav>
        <ul>
          <li>
            {isSmallScreen ? (
              <Link
                activeClassName={classes.activeParent}
                partiallyActive={false}
                onFocus={() => setNavOpen(true)}
                to={`/ `}
              >
                {" "}
                Home
              </Link>
            ) : (
              <Link
                activeClassName={classes.activeParent}
                partiallyActive={false}
                to={`/`}
              >
                {" "}
                Home
              </Link>
            )}
          </li>

          {
            // start
            Object.keys(dets).map(key => {
              const det = dets[key]
              return (
                <li key={det.name}>
                  <Link
                    activeClassName={classes.activeParent}
                    partiallyActive={true}
                    to={`/${det.pageName}`}
                  >
                    {" "}
                    {det.name}
                  </Link>{" "}
                  <ul>
                    {det.schemaId ? (
                      <li>
                        <Link
                          className={
                            hash && hash === `#${det.schemaId}`
                              ? classes.activeChild
                              : ""
                          }
                          to={`/${det.pageName}/#${det.schemaId}`}
                        >
                          {det.name} Schema
                        </Link>
                      </li>
                    ) : null}
                    {det.routes.map(
                      route =>
                        !route.hide && (
                          <li key={route.id}>
                            <Link
                              className={
                                hash && hash === `#${route.id}`
                                  ? classes.activeChild
                                  : ""
                              }
                              to={`/${det.pageName}/#${route.id}`}
                            >
                              {route.title}
                            </Link>
                          </li>
                        )
                    )}
                  </ul>
                </li>
              )
            })
            // end
          }
          <li>
            <Link
              activeClassName={classes.activeParent}
              partiallyActive={true}
              to={`/gtfs`}
            >
              {" "}
              GTFS
            </Link>{" "}
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Sidebar
